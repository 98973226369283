@font-face { font-family: BentonSansBook; font-style: italic; src: url(./assets/fonts/BentonSansBook.otf); }
@font-face { font-family: BentonSansBold; font-style: italic; src: url(./assets/fonts/BentonSansBold.otf); }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button:focus {
    outline: none;
}

.benton-book {
    font-family: BentonSansBook;
}

.benton-bold {
    font-family: BentonSansBold;
}

.coach {
  background-image: url(./assets/images/bottom-left.png), url(./assets/images/top-right.png);
  background-position: left bottom, right top;
  background-repeat: no-repeat;
  background-size: 25%;
}

.easy-edit-component-wrapper {
  input {
    width: 100%;
  }
}

.easy-edit-button-wrapper {
  display: flex !important;
  justify-content: space-around;
}

.easy-edit-validation-error {
  font-size: .75rem;
  text-transform: none;
}

.moveable-line {
  opacity: 0;
}

.moveable-control.moveable-rotation-control {
  background-color: rgba(255, 0, 0, 0.6) !important;
  border: 1px !important;
  width: 20px !important;
  height: 20px !important;
  margin: 50px 0 0 -10px !important;
  border-color: #ccc !important;
}